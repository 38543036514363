<template>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <div class="px-4 sm:px-0">
      <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
        Produkcja
      </h2>
      <div class="min-full-screen flex flex-col justify-center">
        <BarcodeScanner
          v-model="serialNumber"
          @scan-completed="checkSerialNumber"
        />
      </div>
    </div>
    <Modal
      ref="modal"
      :title="serialNumber"
      text="Urządzenie o tym numerze seryjnym nie istnieje w bazie danych. Czy chcesz je dodać i rozpocząć produkcję?"
      :cancel-button-label="$t('cancel')"
      confirm-button-label="Dodaj"
    >
      <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="createDevice">
        Dodaj
      </button>
    </Modal>
  </div>
</template>

<script>
import { api } from "@/assets/js/api-client.js";
import Modal from '@/components/layout/Modal.vue'
import BarcodeScanner from '@/components/inputs/BarcodeScanner.vue'
import { mapState } from 'vuex'
import { deserializeParams } from '@/assets/js/helpers.js'

export default {
  name: "Production",
  components: {
    Modal,
    BarcodeScanner
  },
  data () {
    return {
      serialNumber: ''
    }
  },
  computed: {
    ...mapState(['device', 'specialDeviceTemplates']),
    specialDeviceTemplate () {
      return this.specialDeviceTemplates ? this.specialDeviceTemplates.find(template => {return template.id == process.env.VUE_APP_IFDEVICE_TEMPLATE_ID}) : null
    }
  },
  async mounted () {
    if (!this.specialDeviceTemplates) {
      await this.$store.dispatch('getSpecialDeviceTemplates')
    }
    this.$store.dispatch('setLoading', false)
  },
  methods: {
    async checkSerialNumber () {
      //this.$router.push({path: '/production/'+ this.serialNumber})
      this.$store.dispatch('setLoading', true)

      const device = {
        id: this.serialNumber,
        deviceTemplate: this.specialDeviceTemplate,
        deviceExtension: true
      }
      
      try {
        const result = await this.$store.dispatch('getDevice', device)
        console.log(result)
        if (result.status == 'found') {
          this.$router.push({path: '/production/'+ this.device.id})
        } else {
          this.$refs.modal.show()
        }
      } catch(err) {
        console.log(err)
        this.$notify({
          title: 'Podczas próby połączenia z bazą danych wystąpił błąd:',
          text: err.response.data.detail,
          type: 'error',
          duration: 15000
        })
      }
      this.$store.dispatch('setLoading', false)
    },
    createDevice () {
      this.$refs.modal.close()
      this.$nextTick(() => { this.$store.dispatch('setLoading', true) })
      let device = {
        parameters: {
          'numer_seryjny': this.serialNumber
        },
        deviceExtension: {
          step: 1,
          substep: 0,
          sent: false,
          deviceLogs: [
            {
              movedToStep: 1,
              endedBy: ''
            }
          ]
        },
        deviceTemplate: `/device_templates/${process.env.VUE_APP_IFDEVICE_TEMPLATE_ID}`,
        schemaVersion: `/device_template_schema_versions/${this.specialDeviceTemplate.activeVersion.id}`,
        organisation: `/organisations/${process.env.VUE_APP_ORG_ID}`
      }
      device = deserializeParams(device)
      api.post('/custom_devices', device).then(result => {
        console.log(result)
        device['id'] = result.data.id
        this.$store.dispatch('saveDevice', device)
        this.$store.dispatch('setLoading', false)
        this.$router.push({path: '/production/'+ result.data.id})
      }).catch(err => {
        this.$store.dispatch('setLoading', false)
        if (err) {
          this.$notify({
            title: "Podczas próby połączenia z bazą danych wystąpił błąd:",
            text: err.response.data.detail,
            type: 'error',
            duration: 15000
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
  .min-full-screen{
    min-height: calc(100vh - 194px);
  }
</style>
